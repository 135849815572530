import { createRouter, createWebHistory } from 'vue-router';
import App from '../App.vue';
import ComingSoon from '/src/components/ComingSoon.vue';

const routes = [
    { path: '/', component: App },
    { path: '/coming-soon', component: ComingSoon }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
