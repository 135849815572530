<template>
    <div id="app">
        <Navbar />

        <div class="content">
            <div class="box">
                <img src="../src/assets/500x500.png" alt="Centered Image" />
            </div>

            <div class="text-container">
                <p>$Maverick is the new cutest dog on Solana.</p>
                <p>He's the first German Dobermann on Solana</p>
            </div>

            <button @click="buyMaverick" id="buy">Buy $Maverick</button>

            <h2 class="contract">Contract address</h2>
            <div class="copy-text-container">
                <div class="rounded-box">
                    <h2 @click="copyText" ref="copyText" class="copy-text">
                        maveENoQpf7eiNmAYcFgwVyZBVzkHqttWdfyZmhS1nA
                    </h2>
                </div>
            </div>

            <div class="icon-container" id="links">
                <div class="icon-box">
                    <img
                        src="@/assets/Icons/tg%20icon.webp"
                        alt="Icon 1"
                        style="height: 60%; width: 60%"
                        @click="redirectToTelegram"
                        role="button"
                        tabindex="0"
                    />
                </div>
                <div class="icon-box">
                    <img
                        src="@/assets/Icons/XIcon-removebg-preview.png"
                        alt="Icon 2"
                        @click="redirectToX"
                        role="button"
                        tabindex="0"
                    />
                </div>
                <div class="icon-box">
                    <img src="@/assets/Icons/pumpfunicon-removebg-preview.png" alt="Icon 3" style="position: relative; top: 5%"
                         @click="buyMaverick"
                    />
                </div>
                <div class="icon-box">
                    <img src="@/assets/Icons/DexscreenerIcon.png" alt="Icon 4" />
                </div>
            </div>

            <div class="tokenomics-container">
                <div class="tokenomics" id="tokenomics">
                    <h1>Tokenomics</h1>
                    <ul>
                        <li><strong>Token symbol: $Maverick</strong></li>
                        <li><strong>100% community</strong></li>
                        <li><strong>100% renounced</strong></li>
                        <li><strong>0% taxes</strong></li>
                    </ul>
                </div>
            </div>

            <div class="about-container">
                <div class="about" id="about">
                    <h1>About $Maverick</h1>
                    <p>
                        Meet $Maverick, the most handsome Dobermann in the crypto world! With his sleek coat and sharp, alert eyes, he's sure to captivate your attention.<br><br>
                        Why buy $Maverick? This crypto memecoin isn’t just powerful, he’s also quickly becoming a top contender in the Solana ecosystem.<br><br>
                        Not just about looks, $Maverick’s a smart and strategic pup with a keen sense for spotting the best opportunities in crypto. Get in early and be part of the Maverick movement!<br><br>
                    </p>
                </div>
            </div>

            <!-- Roadmap Section -->
            <div class="roadmap-container" id="roadmap">
                <h2 class="roadmap-title">Roadmap</h2>
                <div class="roadmap-boxes">
                    <div class="roadmap-box">
                        <h3>Phase 1</h3>
                        <ul>
                            <li>Socials launch</li>
                            <li>Join the VC party</li>
                            <li>Raids and buybot</li>
                        </ul>
                    </div>
                    <div class="roadmap-box">
                        <h3>Phase 2</h3>
                        <ul>
                            <li>Paying Dexscreener at 50K MC</li>
                            <li>Burning at 100k</li>
                            <li>Paying trending and ads at 150K MC</li>
                        </ul>
                    </div>
                    <div class="roadmap-box">
                        <h3>Phase 3</h3>
                        <ul>
                            <li>Even more burning at 300k</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '/src/components/Navbar.vue';

export default {
    name: 'App',
    components: {
        Navbar
    },
    data() {
        return {
        };
    },
    methods: {
        copyText() {
            const textElement = this.$refs.copyText;
            if (textElement) {
                const text = textElement.textContent;
                navigator.clipboard.writeText(text)
                    .then(() => alert('Text copied to clipboard!'))
                    .catch(err => alert('Failed to copy text: ' + err));
            } else {
                alert('Text element not found.');
            }
        },
        buyMaverick() {
            window.open('https://pump.fun/maveENoQpf7eiNmAYcFgwVyZBVzkHqttWdfyZmhS1nA', '_blank');
        },
        handleScroll() {
            const aboutSection = document.querySelector('.about');
            const tokenomicsSection = document.querySelector('.tokenomics');
            const roadmapBoxes = document.querySelectorAll('.roadmap-box');

            // About and Tokenomics Section Animation
            const sections = [aboutSection, tokenomicsSection];
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    section.classList.add('show');
                } else {
                    section.classList.remove('show');
                }
            });

            // Roadmap Boxes Animation
            roadmapBoxes.forEach((box, index) => {
                const rect = box.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    box.style.setProperty('--delay', `${index * 0.3}s`); // Set delay based on index
                    box.classList.add('show');
                } else {
                    box.classList.remove('show');
                }
            });
        },
        redirectToTelegram() {
            window.open('https://t.me/Maverick_On_Sol', '_blank');
        },
        redirectToX() {
            window.open('https://www.tiktok.com/@maverickthedobe', '_blank');
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>



<style>
@font-face {
    font-family: 'Comic Sans MS';
    src: url('@/assets/fonts/comici.ttf') format('truetype');
}

html, body, #app {
    font-family: 'Comic Sans MS';
    margin: 0;
    padding: 0;
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

/* General Styles */
body {
    background-image: url('@/assets/100x100.png');
    background-repeat: repeat;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: overlay;
}

.text-container p, button, h2, .copy-text, .tokenomics h1, .about h1, .roadmap-box h3, .roadmap-box ul li {
    font-family: 'Comic Sans MS';
}

.content {
    background-image: url('@/assets/100x100.png');
    background-repeat: repeat;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: overlay;
    padding: 20px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 8% 0 0 0;
}

.box {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 400px;
    aspect-ratio: 1 / 1;
    margin-bottom: 30px;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.text-container {
    text-align: center;
    width: 90vw;
    max-width: 500px;
    margin-bottom: 20px;
}

.text-container p {
    font-family: 'Comic Sans MS';
    font-size: 1.75rem;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.8;
    cursor: default;
}

button {
    text-transform: uppercase;
    font-weight: bold;
    background-color: #705849;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 15px 30px;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 20px;
}

button:hover {
    background-color: #AB7F52;
    color: white;
    transform: scale(1.05);
}

h2.copy-text {
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}

.icon-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.icon-box {
    background-color: #705849;
    border-radius: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.icon-box:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    transition: opacity 0.3s;
}

.icon-box:hover img {
    opacity: 0.8;
}

.confetti-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
}

/* Tokenomics Section */
.tokenomics-container {
    position: relative;
    width: 90vw;
    max-width: 600px;
    margin: 40px auto;
}

.contract {
    font-family: 'Comic Sans MS';
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.copy-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.rounded-box {
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #705849;
    width: 100%;
    max-width: 500px;
}

.copy-text {
    font-family: 'Comic Sans MS';
    font-size: 1rem;
    color: #705849;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    padding: 0 10px;
}

/* Tokenomics Section */
.tokenomics {
    background-color: #705849;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    text-align: left;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    z-index: 1;
}

.tokenomics h1 {
    font-family: 'Comic Sans MS';
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.tokenomics ul {
    list-style: none;
    padding: 0;
}

.tokenomics ul li {
    font-family: 'Comic Sans MS';
    font-size: 1.25rem;
    color: #ffffff;
    margin: 10px 0;
    position: relative;
    padding-left: 1.5em;
    cursor: default; /* Ensure cursor does not change */
}

.tokenomics ul li::before {
    content: '• ';
    color: #ffffff;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
}

.about-container {
    position: relative;
    width: 90vw;
    max-width: 600px;
    margin: 40px auto;
}

.about {
    background-color: #705849;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    text-align: left;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    z-index: 1;
}

.about h1 {
    font-family: 'Comic Sans MS';
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.about p {
    font-family: 'Comic Sans MS';
    font-size: 1.25rem;
    color: #ffffff;
    line-height: 1.8;
}

/* Roadmap Section */
.roadmap-container {
    width: 90vw;
    max-width: 1750px;
    margin: 40px auto;
    text-align: center;
}

.roadmap-title {
    font-family: 'Comic Sans MS';
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.roadmap-boxes {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
}

.roadmap-box {
    background-color: #705849;
    border-radius: 10px;
    padding: 30px;
    width: 30%;
    min-width: 250px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.8s ease-out, opacity 0.8s ease-out;
    box-sizing: border-box;
    transition-delay: var(--delay, 0s);
    margin-bottom: 50px;
}

.roadmap-box.show {
    transform: translateX(0);
    opacity: 1;
}

.roadmap-box:hover {
    transform: scale(1.05);
}

.roadmap-box h3 {
    font-family: 'Comic Sans MS';
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 15px;
}

.roadmap-box ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin: 0;
    font-family: 'Comic Sans MS';
    font-size: 1.5rem;
    color: #ffffff;
    text-align: left;
}

.roadmap-box ul li {
    margin: 15px 0;
}


#generate-sticker {
    text-transform: uppercase;
    font-weight: bold;
    background-color: #705849;
    color: #333;
    border: none;
    border-radius: 50px;
    padding: 15px 30px;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 20px;
}

#generate-sticker:hover {
    background-color: #AB7F52;
    color: white;
    transform: scale(1.05);
}

.uniform-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

/* Animation trigger */
.tokenomics.show,
.about.show {
    opacity: 1;
    transform: translateX(0);
}

/* Hide large images on small screens */
.peanut-cook,
.peanut-joint {
    display: none; /* Hide these images by default */
}

/* Display large images only on larger screens */
/*@media (min-width: 769px) {*/
/*    .peanut-cook {*/
/*        display: block;*/
/*        width: 750px;*/
/*        height: auto;*/
/*        position: absolute;*/
/*        top: 50%;*/
/*        left: 120%;*/
/*        transform: translateY(-50%);*/
/*    }*/

/*    .peanut-joint {*/
/*        display: block;*/
/*        width: 750px;*/
/*        height: auto;*/
/*        position: absolute;*/
/*        top: 50%;*/
/*        left: -120%;*/
/*        transform: translateY(-50%);*/
/*    }*/
/*}*/

/* Media query for smaller screens */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
        align-items: center;
    }

    .about, .tokenomics {
        width: 90vw;
        padding: 15px;
    }

    .content {
        margin: 27% 0 0 0;
    }

    /*.peanut-joint {*/
    /*    width: 200px;*/
    /*    left: -250px;*/
    /*}*/
}
</style>

