<template>
    <nav class="navbar">
        <button class="menu-toggle" @click="toggleMenu">
            ☰
        </button>
        <ul :class="{ 'active': menuOpen }">
            <li><a href="#buy" @click="scrollToSection('buy', $event)">Buy</a></li>
            <li><a href="#links" @click="scrollToSection('links', $event)">Links</a></li>
            <li><a href="#tokenomics" @click="scrollToSection('tokenomics', $event)">Tokenomics</a></li>
            <li><a href="#about" @click="scrollToSection('about', $event)">About</a></li>
            <li><a href="#roadmap" @click="scrollToSection('roadmap', $event)">Roadmap</a></li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'Nav-bar',
    data() {
        return {
            menuOpen: false
        };
    },
    methods: {
        scrollToSection(id, event) {
            event.preventDefault();
            const section = document.getElementById(id);
            if (section) {
                const sectionRect = section.getBoundingClientRect();
                const navbarHeight = document.querySelector('.navbar').offsetHeight;
                const offset = sectionRect.top + window.scrollY - (window.innerHeight / 2) + (sectionRect.height / 2) - navbarHeight / 2;
                window.scrollTo({
                    top: offset,
                    behavior: 'smooth'
                });
            }
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        }
    }
}
</script>


<style scoped>
@font-face {
    font-family: 'Comic Sans MS';
    src: url('@/assets/fonts/comici.ttf') format('truetype');
}

.navbar {
    font-family: 'Comic Sans MS';
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #705849;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 15px 15px 0px 15px;
}

.navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.navbar li {
    margin: 0 10px;
}

.navbar a {
    font-family: 'Comic Sans MS';
    text-decoration: none;
    text-transform: uppercase;
    color: #333;
    font-weight: bold;
    padding: 0.5em 1em;
    display: block;
}

.navbar a:hover {
    color: white;
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #B78D63;
        width: 100%;
        max-width: 400px; /* Increase the max-width here */
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    .navbar ul.active {
        opacity: 1;
        visibility: visible;
        width: 200px;
    }

    .navbar a:hover {
        color:white;
    }

    .menu-toggle {
        display: block;
    }

    .navbar li {
        margin: 10px 0;
    }

    .navbar a {
        font-size: 1.25rem;
    }
}
</style>
