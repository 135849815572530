<template>
    <div class="content">
        <div class="coming-soon-box">
            <h1>Coming Soon</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComingSoon'
}
</script>

<style scoped>
.content {
    padding: 20px;
    background-color: #5F5957;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 5%;
}

.coming-soon-box {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming-soon-box h1 {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #B78D63;
}
</style>
